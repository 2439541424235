"use strict";
// CONSTANTS
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEMO = exports.CHEM_PROJECT = exports.BASE_PATH = void 0;
// export const BASE_PATH = "dist/"; // use this when you want to use the index.html in the root folder
exports.BASE_PATH = ""; //use this when you want to use the index.html in the dist folder --> for docker
exports.CHEM_PROJECT = true; // use this flag to show that it the tool is used for chem data
// export const CHEM_PROJECT = false; // use this for the default PSE and for netlify
exports.DEMO = true; // use this flag for DEMO purposes for the paper
// export const DEMO = false; // use this as default
